import DocumentActionTypes from "./document.types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { clientInvestSdk } from "@utils/clientInvestSdk";

interface Input {
  commitmentId: string;
}
export const downloadInvestmentStatementDocumentAsync = createAsyncThunk(
  DocumentActionTypes.DOWNLOAD_INVESTMENT_STATEMENT,
  async (data: Input, { rejectWithValue }) => {
    try {
      await clientInvestSdk.downloadInvestmentStatementDocument(data);
      const date = new Date().toISOString();
      const lastDownloadDateStore = localStorage.getItem(
        "investment_statement_download"
      );
      const parseLastDownloadDate = JSON.parse(lastDownloadDateStore ?? "[]");
      const storedMap = new Map(parseLastDownloadDate);
      const lastDownloadDateMap = new Map([...storedMap.entries()]);
      lastDownloadDateMap.set(data.commitmentId, date);
      localStorage.setItem(
        "investment_statement_download",
        JSON.stringify(Array.from(lastDownloadDateMap.entries()))
      );
    } catch (e: any) {
      if (e.response?.status !== 403) {
        toast.error("Something went wrong. Try again or contact support");
      }

      return rejectWithValue(e);
    }
  }
);

export const fetchDocumentsAPI = createAsyncThunk(
  DocumentActionTypes.FETCH_DOCUMENTS_API,
  async (_, { rejectWithValue }) => {
    try {
      const response = await clientInvestSdk.getDocuments();
      // @ts-expect-error TODO: fix this in the SDK
      return (response.data?.documents || []) as DocumentEntry[];
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);
