import { DocumentEntry } from "@ifgengineering/client-invest-sdk";
import { fetchDocumentsAPI } from "./document.actions";

export interface DocumentState {
  neverLoaded: boolean;
  loading: boolean;
  data: DocumentEntry[];
}

export const INITIAL_STATE: DocumentState = {
  neverLoaded: true,
  loading: false,
  data: [],
};

const documentReducer = (state = INITIAL_STATE, action: any): DocumentState => {
  switch (action.type) {
    case fetchDocumentsAPI.pending.type:
      return { ...state, loading: true };
    case fetchDocumentsAPI.fulfilled.type:
      return {
        ...state,
        neverLoaded: false,
        loading: false,
        data: action.payload,
      };
    case fetchDocumentsAPI.rejected.type:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default documentReducer;
