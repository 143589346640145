import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer, { AuthState } from "./auth/auth.reducer";
import { PortfolioState } from "./portfolio/portfolio.reducer";
import { CommitmentState } from "./commitment/commitment.reducer";
import dealReducer, { DealState } from "./deal/deal.reducer";
import investorReducer, { InvestorState } from "./investor/investor.reducer";
import commitmentReducer from "@state/commitment/commitment.reducer";
import commitmentPreviewReducer, {
  CommitmentPreviewState,
} from "./commitmentPreview/commitmentPreview.reducer";
import entityReducer, { EntityState } from "./entity/entity.reducer";
import notificationReducer, {
  NotificationState,
} from "./notification/notification.reducer";
import portfolioReducer from "./portfolio/portfolio.reducer";
import remoteConfig, {
  TRemoteConfigState,
} from "./remoteConfiguration/remoteConfiguration.reducer";
import bankAccountReducer from "./bankAccount/bankAccount.reducer";
import { BankAccountState } from "./bankAccount/bankAccount.types";
import paymentReducer, { PaymentState } from "./payment/payment.reducer";
import documentReducer, { DocumentState } from "./document/document.reducer";
export type RootState = {
  auth: AuthState;
  commitment: CommitmentState;
  commitmentPreview: CommitmentPreviewState;
  deal: DealState;
  entity: EntityState;
  portfolio: PortfolioState;
  investor: InvestorState;
  notification: NotificationState;
  remoteConfig: TRemoteConfigState;
  bankAccount: BankAccountState;
  payment: PaymentState;
  document: DocumentState;
};

// TODO: Get rid of this persistor without break the build -> TR-232
const persistConfig = {
  key: "root",
  storage,
  // For now, I'm configuring it to persist nothing
  whitelist: [],
  version: 3,
};

const rootReducer = combineReducers<RootState>({
  auth: authReducer,
  commitment: commitmentReducer,
  commitmentPreview: commitmentPreviewReducer,
  deal: dealReducer,
  entity: entityReducer,
  portfolio: portfolioReducer,
  investor: investorReducer,
  notification: notificationReducer,
  remoteConfig: remoteConfig,
  bankAccount: bankAccountReducer,
  payment: paymentReducer,
  document: documentReducer,
});

export default persistReducer(persistConfig, rootReducer);
