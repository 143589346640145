import CommitmentActionTypes from "./commitment.types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { CUR8_SERVER } from "gatsby-env-variables";
import { toast } from "react-toastify";
import { navigate } from "@reach/router";
import { clientInvestSdk } from "@utils/clientInvestSdk";
import { CommitmentInput } from "@ifgengineering/client-invest-sdk";

export const fetchCommitmentsAPI = createAsyncThunk(
  CommitmentActionTypes.FETCH_ALL_COMMITMENTS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await clientInvestSdk.getCommitments();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCommitmentsByDealId = createAsyncThunk(
  CommitmentActionTypes.FETCH_DEAL_COMMITMENTS,
  async (dealId: string, { rejectWithValue }) => {
    try {
      const response = await clientInvestSdk.getCommitments({ dealId });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export interface CreateOrUpdateCommitmentAsyncInput extends CommitmentInput {
  commitmentId?: string;
}

export const createOrUpdateCommitmentAsync = createAsyncThunk(
  CommitmentActionTypes.CREATE_COMMITMENT,
  async (
    payload: CreateOrUpdateCommitmentAsyncInput,
    { rejectWithValue, dispatch }
  ) => {
    const { commitmentId, ...commitmentInput } = payload;
    try {
      const response = await clientInvestSdk.postCommitment({
        ...commitmentInput,
      });

      navigate(`/app/deals/${commitmentInput.dealId}?referrer=commitment`);

      if (commitmentId) {
        await dispatch(
          fetchCommitmentsByDealId(commitmentInput?.dealId as string)
        );
        toast.success("Investment updated successfully.");
      } else {
        toast.success("Investment processed successfully.");
      }
      return response.data;
    } catch (e: any) {
      if (e.response?.status !== 403) {
        toast.error("Something went wrong. Try again or contact support");
      }

      return rejectWithValue(e);
    }
  }
);

type CommitmentCheckoutInput = {
  successUrl: string;
  cancelUrl: string;
  commitmentId: string;
};

type CommitmentCheckoutResponse = {
  useStripeCheckoutSession: boolean;
  stripeCheckoutSessionId: string;
};

export const commitmentCheckout = createAsyncThunk(
  CommitmentActionTypes.CHECKOUT_COMMITMENT,
  async (
    { cancelUrl, successUrl, commitmentId }: CommitmentCheckoutInput,
    { rejectWithValue }
  ) => {
    try {
      const response: AxiosResponse<CommitmentCheckoutResponse> =
        await axios.post(
          `${CUR8_SERVER}/commitments/${commitmentId}/checkout`,
          {
            cancelUrl,
            successUrl,
          },
          {
            withCredentials: true,
          }
        );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
